import request from "@/utils/request";

/**
 * 获取组织机构列表[旧接口，保留查看数据结构]
 */
export function getLocalDept(parentId=0) {
    return request({
        url: '/api/zeekr-system/dept/load-now-dept-person',
        method: 'get',
        params: {
            parentId
        }
    })
}

/**
 * 获取组织机构列表[新接口]
 * @param parentId
 * @returns {AxiosPromise}
 */
export function getLocalDeptNew(parentDeptId=0) {
    return request({
        url: '/api/zeekr-article-new/zeekrUser/listDept',
        method: 'get',
        params: {
            parentDeptId
        }
    })
}

