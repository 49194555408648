<template>
  <div class="memberItem">
    <div class="memberItem_content">
      <AvatarFrame class="user-avatar" :user-info="memberItem"></AvatarFrame>
      <p v-if="displayName" v-html="displayName"></p>
      <p v-else>{{ name }}</p>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {USER_IMAGE} from '@/config/config'

export default {
  components:{
    AvatarFrame
  },
  props: {
    avatar: String,
    name: String,
    displayName: String,
    memberItem: {
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      USER_IMAGE
    }
  }
}
</script>

<style lang="scss" scoped>
.memberItem {
  background-color: white;
  padding: 0 20px;
  position: relative;
  font-size: 14px;
  color: $titleColor;

  &_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;

    .user-avatar {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
  }

  &:not(:last-child) > div {
    border-bottom: solid 1px #EBEBEB;
  }
}

.memberItem_content {
  p {
    margin: 14px 0;
  }
}
</style>
