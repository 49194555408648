<template>
  <div class="organization">
    <pageHead :isSetDocTitle="false" :is-show-page-head="true" :title="pageTitle">
      <div slot="left" class="organization_headLeft">
        <div v-if="departmentLevelArr.length > 0" class="closeText" @click="handleBack">上一级</div>
      </div>
      <p v-if="isShowTopConfirm" class="confirmText" slot="right" @click="handleConfirm">确认</p>
      <div v-else class="flex-row-center confirmText" slot="right" @click="handleClose">
        <van-icon size="18" color="#c8c9cc" name="cross" />
      </div>
    </pageHead>
    <van-search
      ref="searchOrg"
      v-model="searchText"
      show-action
      shape="round"
      placeholder="请输入搜索成员名"
      @search="onSearch"
      @input='handleSearchInput'
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <!--    <navList :options="departmentLevelArr"/>-->
    <!-- todo   搜索结果页面-->
    <div v-show="showSearchResult" class="searchContent" v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy">
      <memberItem v-for="item in searchResult " :memberItem="item" v-bind="item" :key="item.id" @click.native="handleSelectMember(item)" />
      <van-empty v-if="searchResult.length===0" description="暂无符合条件的成员" />
      <no-more v-if="!listQueryParams.hasNextPage && searchResult.length>0" />
    </div>
    <!-- todo   非搜索结果页面-->
    <div class="organization_content" v-if="!showSearchResult">
      <!-- <member-item :avatar="USER_IMAGE" name="超级管理员" @click.native="handleAttAdmin"/> -->
      <van-empty v-if="memberList.length===0&&departmentList.length===0" description="该部门无成员及子部门" />
      <departmentItem @selectOrg="handleSelectOrg" :item="item" v-for="item in departmentList " :key="item.id"
        v-bind="item"
        @click.native="handleDepartmentClick(item)" />
      <memberItem v-for="item in memberList" :memberItem="item" v-bind="item" :key="item.id" @click.native="handleSelectMember(item)" />
    </div>
    <!--    选择的组织-->
    <!--    <p v-for="itemOrg in selectOrgList" style="padding: 0 6px;" :key="itemOrg.id">-->
    <!--      {{itemOrg.name}}-->
    <!--    </p>-->
    <!-- todo   确认添加-->
    <div class="flex-row-space-between-hCenter footer" v-if="!isSourceAiTe">
      <div class="flex-column left-list">
        <!-- <p v-if="attAdmin" class="userTag" @click="attAdmin = false">@超级管理员</p> -->
        <ul class="organization_selectedUserList" v-if="selectMemberList.length>0 || attAdmin">
          <li class="flex-column-hCenter userTag" v-for="item in selectMemberList" :key="item.id"
            @click="handleRemoveSelectedUser(item)">
            <van-badge>
              <div class="flex-column-center">
                <van-image
                  round
                  fit="cover"
                  width="36"
                  height="36"
                  :src="item.avatar"
                />
                <span style="word-break: keep-all;">{{ item.name }}</span>
              </div>
              <template #content>
                <van-icon name="cross" class="badge-icon" />
              </template>
            </van-badge>
          </li>
        </ul>
      </div>
      <div class="flex-center-row submit-btn" style="width: 130px">
        <van-button color="#FE5800" round @click="handleConfirm" :disabled="!selectMemberList.length" type="primary">
          确认添加({{ selectMemberList.length }})
        </van-button>
      </div>
    </div>

    <!-- todo   @的展示样式-->
    <div class="organization_selectedUserListAiTe" v-if="(selectMemberList.length>0 || attAdmin) && isSourceAiTe">
      <!-- <p v-if="attAdmin" class="userTag" @click="attAdmin = false">@超级管理员</p> -->
      <p class="userTag" v-for="item in selectMemberList" :key="item.id" @click="handleRemoveSelectedUser(item)">
        <span>@{{ item.name }}</span>
      </p>
    </div>

  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import departmentItem from "./components/departmentItem";
import memberItem from "./components/memberItem";
import NoMore from '@/components/uitls/NoMore'
// import navList from "./components/navList";
import {getLocalDeptNew} from '@/api/dept'
import {getUserByNameNew} from '@/api/suser'
import {USER_IMAGE} from "@/config/config";

const TYPE_DEPARTMENT = 1
const TYPE_MEMBER = 2
export default {
  props: {
    // 是否显示顶部的确认按钮
    isShowTopConfirm: {
      type: Boolean,
      default: false
    },
    // 是否来源于@页面
    isSourceAiTe: {
      type: Boolean,
      default: false
    },
    fromRouteName: String,
    selectMembers: {
      type: String,
      default: ''
    },
    id: String,
    quoteId: String,
    // 选中的用户列表
    haveMemberList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    pageHead,
    departmentItem,
    memberItem,
    NoMore,
    // navList
  },
  computed: {
    // 页面标题
    pageTitle () {
      return this.currentDepartment.name || '极氪智能科技'
    },
    currentDepartment () {
      const [currentDepartment] = this.departmentLevelArr.slice(-1)
      return currentDepartment || {}
    },
    // 部门列表
    departmentList () {
      const {organizationData} = this
      return organizationData.filter(item => (item.type === TYPE_DEPARTMENT))
    },
    // 部门成员列表
    memberList () {
      const {organizationData} = this
      return organizationData.filter(item => (item.type === TYPE_MEMBER))
    }
  },
  watch: {
    currentDepartment: {
      // 开始侦听后立即调用一次
      immediate: true,
      deep: true,
      handler (currentDepartment) {
        this.getOrganizationData(currentDepartment.id)
      }
    }
  },
  created () {
    this.selectMemberList = this.haveMemberList
  },
  mounted() {
    // this.$refs.searchOrg.querySelector("input").focus();
    // 默认选中传过来的数据，暂时废弃
    // if (this.$route.query.selectMembers) {
    //   this.selectMemberList = this.$route.query.selectMembers.split(',').filter(item => item).map(item => {
    //     const [id, name] = item.split(':')
    //     return {id, name}
    //   })
    // }
  },
  data () {
    return {
      // 选中的用户列表
      selectMemberList: [],
      // 选中的组织列表
      selectOrgList: [],
      // 部门层级数组
      departmentLevelArr: [],
      // 搜索内容
      searchText: "",
      oldSearchText: "",
      // 组织机构数据
      organizationData: [],

      superAdminList: [],
      USER_IMAGE,
      attAdmin: false,
      searchResult: [],
      showSearchResult: false,
      listQueryParams: {
        pageNum: 1,
        pageSize: 15,
        hasNextPage: true,
      },
      busy: true
    }
  },
  methods: {
    /**
     * 返回事件
     */
    handleBack () {
      const {departmentLevelArr} = this
      if (departmentLevelArr.length > 0) {
        departmentLevelArr.pop()
        return
      }
      this.$emit('close')
    },
    // 跳转源页面
    goSourcePage (name, selectList, id, tag, quoteId) {
      const itemNameList = ['myCreateCircle']
      if (itemNameList.indexOf(name) !== -1) {
        this.$router.replace({
          name,
          query: {
            selectMembers: JSON.stringify(selectList),
            id: id,
            tag,
            quoteId
          }
        })
      } else {
        this.$router.replace({
          name,
          query: {
            selectMembers: selectList.map(item => `${item.id}:${item.name}`).join(','),
            id: id,
            tag,
            quoteId
          }
        })
      }
    },
    /**
     * 确认事件
     */
    handleConfirm () {
      this.$emit('submit', this.selectMemberList)
    },
    // 清除选中的数据
    clearSelectMemberList () {
      this.selectMemberList = []
    },
    /**
     * 关闭当前页面返回
     */
    handleClose () {
      this.$emit('close')
    },
    /**
     * 搜索事件
     */
    // handleSearch() {

    // },
    /**
     * 选中成员
     */
    handleSelectMember (member) {
      if (this.selectMemberList.map(item => parseInt(item.id)).includes(parseInt(member.id))) {
        this.selectMemberList = this.selectMemberList.filter(item => (parseInt(item.id) !== parseInt(member.id)))
      } else {
        this.selectMemberList.unshift(member)
      }
    },
    /**
     * 选中组织
     */
    handleSelectOrg (member) {
      if (this.selectOrgList.map(item => parseInt(item.id)).includes(parseInt(member.id))) {
        this.selectOrgList = this.selectOrgList.filter(item => (parseInt(item.id) !== parseInt(member.id)))
      } else {
        this.selectOrgList.unshift(member)
      }
    },
    /**
     * 移除选中用户
     * @param userId
     */
    handleRemoveSelectedUser ({id: userId}) {
      this.selectMemberList = this.selectMemberList.filter(item => item.id !== userId)
    },
    /**
     * 组织机构被选中
     * @param currentDepartment
     */
    handleDepartmentClick (currentDepartment) {
      this.departmentLevelArr.push(currentDepartment)
    },
    /**
     * 获取组织机构数据
     * @param departmentId
     * @returns {Promise<void>}
     */
    async getOrganizationData (departmentId) {
      // this.organizationData
      const res = await getLocalDeptNew(departmentId) || []
      if (res.length > 0) {
        this.organizationData = res.map(item => {
          if (item.isUserInfo) {
            return {
              ...item,
              avatar: item.userBasicInfo.avatar,
              avatarFrameUrl: item.userBasicInfo.avatarFrameUrl,
              id: item.userBasicInfo.userId,
              name: item.userBasicInfo.username,
              superAdmin: 0,
              type: 2
            }
          } else {
            return {
              ...item,
              avatar: '',
              avatarFrameUrl: '',
              id: item.deptBasicInfo.id,
              name: item.deptBasicInfo.deptName,
              superAdmin: -1,
              type: 1
            }
          }
        })
      } else {
        this.organizationData = []
      }
    },
    // 搜索
    onSearch () {
      if (this.oldSearchText === this.searchText) return;
      this.searchResult = []
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 15,
        hasNextPage: true,
      }
      if (this.searchText.trim()) {
        // this.loadList()
        this.busy = false
      }
    },
    loadMore () {
      this.loadList()
    },
    async loadList () {
      let listQueryParams = this.listQueryParams;
      listQueryParams.username = this.searchText;
      this.oldSearchText = this.searchText;
      if (listQueryParams.hasNextPage) {
        const result = await getUserByNameNew(listQueryParams) || {}
        let {records} = result || []
        let res = records.map(item => {
          return {
            ...item,
            id: item.userId,
            name: item.username,
            superAdmin: 0,
            type: 2
          }
        })
        this.showSearchResult = true
        listQueryParams.hasNextPage = !(res.length < 15)
        if (!res.length) return;

        this.searchResult = res
        console.log(result)
        listQueryParams.pageNum = listQueryParams.pageNum + 1
      }
    },
    // 搜索框的值改变
    handleSearchInput () {
      if (!this.searchText.trim()) {
        this.showSearchResult = false
        this.searchResult = []
        this.listQueryParams = {
          pageNum: 1,
          pageSize: 10,
          hasNextPage: true,
        }
      }
      this.busy = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-button--disabled {
  opacity: 1;
  background: #888888 !important;
  border-color: #888888 !important;
}

::v-deep .van-badge{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 0;
}

.organization {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_headLeft {
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 100%;

    .closeText {
      margin: 0;
      padding: 10px;
      color: $titleColor;
    }
  }

  .confirmText {
    font-size: 14px;
    color: $titleColor;
  }

  .searchContent {
    flex: 1;
    overflow-y: auto;
  }

  &_content {
    flex: 1;
    overflow-y: auto;
  }

  .footer {
    height: 112px;
    border-top: 1px solid #E3E3E3;
    overflow-x: hidden;

    .left-list {
      flex: 1;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }


  &_selectedUserList {
    background-color: white;
    padding: 20px 0;
    //margin: -5px;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    overflow-x: auto;


    .userTag {
      padding: 0 10px;
      margin: 5px;
      font-size: 12px;
    }
  }

  &_selectedUserListAiTe {
    background-color: white;
    padding: 20px;
    margin: -5px;
    display: flex;
    flex-wrap: wrap;

    .userTag {
      background-color: $mainColor;
      color: white;
      height: 20px;
      border-radius: 10px;
      padding: 0 10px;
      margin: 5px;
      font-size: 12px;
    }
  }


  .submit-btn {
    width: 130px;
  }

}
</style>
